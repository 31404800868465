<template>
  <Card v-test="'returns-payment'">
    <Heading
      v-t="'returns.payment'"
      level="3" />
    <Paragraph v-t="{ path: 'returns.payment_description', args: { price } }" />
  </Card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';
import { STORE_MODULE_RETURNS } from '@/store/modules/returns/constants';
import { hasEmail } from '@/mixins/hasEmail';

export default {
  name: 'ReturnsPayment',
  components: { Paragraph, Heading, Card },
  mixins: [hasEmail],
  emits: ['addHooks', 'setButtonAttributes'],

  computed: {
    ...mapState(STORE_MODULE_RETURNS, ['returnData']),
    ...mapGetters(['fields']),

    /**
     * Return the price of the return shipment if there are payment instructions present.
     *
     * @returns {string}
     */
    price() {
      const intl = new Intl.NumberFormat(this.$i18n.locale.replace('_', '-'), {
        style: 'currency',
        currency: this.returnData.payment_instructions.price.currency,
      });

      return intl.format(this.returnData.payment_instructions.price.amount / 100);
    },
  },

  watch: {
    '$i18n.locale'() {
      this.setButtonPaymentAttributes();
    },
  },

  created() {
    this.setButtonPaymentAttributes();

    // Add the onNext function to the next button click hooks
    this.$emit('addHooks', {
      clickNext: [() => {
        window.location.assign(this.returnData.payment_instructions.payment_url);
      }],
    });
  },

  methods: {
    setButtonPaymentAttributes() {
      this.$emit('setButtonAttributes', {
        next: {
          show: true,
          text: this.$t('returns.button_payment'),
          variant: 'light',
          iconRight: 'external',
        },
      });
    },
  },
};
</script>
