export const hasEmail = {
  data() {
    return {
      mutableEmail: null,
    };
  },

  computed: {
    email: {
      get() {
        return this.mutableEmail ?? this.returnData?.email?.email ?? '';
      },
      set(value) {
        this.mutableEmail = value;
      },
    },
  },
};
